import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: "root",
})
export class AuthSessionGuard implements CanActivate {
  constructor(private router: Router, private navCtrl: NavController,private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise(async (resolve) => {
      const hasPreviousSession = await this.authService.updateAuthStateIfTokenProvided();
      if (hasPreviousSession) { this.navCtrl.navigateRoot(["restoring-session"]); }
      resolve(!hasPreviousSession);
    });
  }
}
