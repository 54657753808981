import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Storage } from "@ionic/storage";
import { Observable, throwError, from } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { AppStorageKey, StatusHttp } from "../static";
import { AuthTokenObject } from "../models";
import { Router } from "@angular/router";

const TOKEN_KEY = "token";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  isLoading: boolean = false;

  public onlineOffline: boolean = navigator.onLine;

  constructor(
    public loadingCtrl: LoadingController,
    public storage: Storage,
    private toastCtrl: ToastController,
    private router: Router
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.storage.get(AppStorageKey.AuthTokenObject)).pipe(
      switchMap((tokenObj: AuthTokenObject) => {

        if (tokenObj) {
          if (!request.headers.has("Authorization")) {
            request = request.clone({
              headers: request.headers.set(
                "Authorization",
                `${tokenObj.token_type} ${tokenObj.token}`
              ),
            });
          }
        }

        if (!request.headers.has("Content-Type")) {
          request = request.clone({
            headers: request.headers.set("Content-Type", "application/json"),
          });
        }

       
        return next.handle(request).pipe(
          catchError((error: HttpErrorResponse) => {
            let errorMessage = "";
            if (error.error && error.error.message) {
              // client-side error
              errorMessage = `Error: ${error.error.message}`;
              this.presentErrorToast(errorMessage);
            }else if(error.error.errors != undefined && Array.isArray(error.error.errors)){
              for(let i in error.error.errors){
                errorMessage = error.error.errors[i];
                this.presentErrorToast(errorMessage);
              }
            }else {
              // server-side error
              errorMessage = `Error Code: ${error.status}`;
              if(error.status == 0){
                errorMessage = 'Ha ocurrido un error, revisa tu conexión e intenta nuevamente.'
              }
              this.presentErrorToast(errorMessage);
            }

            if(error.status == 401){
              this.storage.remove(AppStorageKey.AuthTokenObject);
              this.router.navigate(['/log-in']);
            }

            return throwError(errorMessage);
          })
        );
      })
    );
  }

  // Presenta el toast con el error
  async presentErrorToast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: "top",
      color: "danger",
      cssClass: "toast",
    });
    toast.present();
  }

  // Creación del loading
  async presentLoading() {
    this.isLoading = true;
    return await this.loadingCtrl.create({
      message: 'Estamos procesando tu transacción por favor espera un momento.'
    }).then((a) => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss();
        }
      });
    });
  }

  // Cierre del loading
  async dismissLoading() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss();
  }
}
